import React, { useEffect } from "react";
import FooterHub from "../comp/FooterHub";
import Header from "../comp/Header";
import { Helmet } from "react-helmet";
import NewFooter from "../comp/NewFooter";

const PrivacyPolicyScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rareprob | Privacy Policy</title>
      </Helmet>
      <nav>
        <Header color="bg-[#6B009C]" page="privacy" />
      </nav>
      <main id="start">
        <div className="pt-20 w-5/6 md:w-3/4 px-1  break-words md:px-0 m-auto text-gray-700 text-xl">
          <h1 className="mt-20 font-bold text-5xl text-gray-800">
            Privacy Policy
          </h1>
          <p className="mt-4 font-medium">Last Update: May 8, 2022</p>
          <p className="mt-4 font-medium">
            ASD Dev Video Player for All Format (“us”, “we”, or “our”) operates
            websites, games, mobile applications and other products (“Games”)
            (hereinafter referred to as the “Service”).
          </p>
          <p className="mt-4 font-medium">
            This page informs you of our policies regarding the collection, use
            and disclosure of personal data when you use our Service and the
            choices you have associated with that data
          </p>
          <p className="mt-4 font-medium">
            We use your data to provide and improve the Service. By using the
            Service, you agree to the collection and use of information in
            accordance with this policy. Unless otherwise defined in this
            Privacy Policy, the terms used in this Privacy Policy have the same
            meanings as in our Terms and Conditions.
          </p>
          <h1 className="mt-10 text-gray-800 text-3xl font-bold">
            Definitions
          </h1>
          <ul className="ml-10 mt-2">
            <li className="list-disc font-semibold">
              Service
              <p className="font-normal">
                Service means games, mobile applications, other products
                (“Games”), and websites operated by ASD Dev Video Player for All
                Format
              </p>
            </li>
            <li className="list-disc font-semibold">
              Personal Data <br />
              <p className="font-normal">
                Personal Data means data about a living individual who can be
                identified from those data (or from those and other information
                either in our possession or likely to come into our possession)
              </p>
            </li>
            <li className="list-disc font-semibold">
              Usage Data <br />
              <p className="font-normal">
                Usage Data is data collected automatically either generated by
                the use of the Service or from the Service infrastructure itself
                (for example, the duration of a page visit).
              </p>
            </li>
            <li className="list-disc font-semibold">
              Cookies <br />
              <p className="font-normal">
                Cookies are small files stored on your device (computer or
                mobile device).
              </p>
            </li>
            <li className="list-disc font-semibold">
              Data Controller <br />
              <p className="font-normal">
                Data Controller means the natural or legal person who (either
                alone or jointly or in common with other persons) determines the
                purposes for which and the manner in which any personal
                information are, or are to be, processed. For the purpose of
                this Privacy Policy, we are a Data Controller of your Personal
                Data.
              </p>
            </li>
            <li className="list-disc font-semibold">
              Data Processors (or Service Providers) <br />
              <p className="font-normal">
                Data Processor (or Service Provider) means any natural or legal
                person who processes the data on behalf of the Data Controller.
                We may use the services of various Service Providers in order to
                process your data more effectively.
              </p>
            </li>
            <li className="list-disc font-semibold">
              Data Subject (or User) <br />
              <p className="font-normal">
                Data Subject is any living individual who is using our Service
                and is the subject of Personal Data.
              </p>
            </li>
          </ul>
          <h1 className="text-3xl mt-10 font-bold text-gray-900">
            Information collection and use
          </h1>
          <p className="mt-2 ">
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </p>
          <h1 className="mt-8 text-3xl font-bold text-black">
            Types of Data Collected
          </h1>
          <p className="mt-2 font-semibold">Personal Data</p>
          <p className="mt-2">
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you (“Personal Data”). Personally identifiable information
            may include, but is not limited to:
          </p>
          <p className="font-semibold mt-4">Cookies and Usage Data</p>
          <p className="mt-2">Usage Data</p>
          <p className="mt-2">
            We may also collect information that your browser sends whenever you
            visit our Service or when you access the Service by or through a
            mobile device (“Usage Data”).
          </p>
          <p className="mt-2">
            This Usage Data may include information such as your computer’s
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service that you visit, the time and date
            of your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </p>
          <p className="mt-2">
            When you access the Service with a mobile device, this Usage Data
            may include information such as the type of mobile device you use,
            your mobile device unique ID, the IP address of your mobile device,
            your mobile operating system, the type of mobile Internet browser
            you use, unique device identifiers and other diagnostic data.
          </p>
          <li className="mt-8 font-semibold">
            Sensitive Personal Data and Information We Do Not Collect
          </li>
          <p className="mt-2">
            We do not ask or collect any Sensitive Personal Data or information
            such as passwords related to other services, financial information
            such as bank account or credit card or debit card or other payment
            instrument details, physical, physiological, and mental health
            conditions, sexual orientation, medical records, and history, or
            biometric information.
          </p>
          <p className="mt-8 font-semibold">Tracking & Cookies Data</p>
          <p className="mt-2">
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information.
          </p>
          <p className="mt-2">
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags and scripts to collect and track
            information and to improve and analyse our Service.
          </p>
          <p className="mt-2">
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
          </p>
          <p className="mt-2 font-semibold">Examples of Cookies we use:</p>
          <li className="mt-2 text-lg">
            <strong className="font-medium">Session Cookies</strong> <br /> We
            use Session Cookies to operate our Service.
          </li>
          <li className="mt-2 text-lg">
            <strong className="font-medium">Preference Cookies</strong> <br />{" "}
            We use Preference Cookies to remember your preferences and various
            settings.
          </li>
          <li className="mt-2 text-lg">
            <strong className="font-medium">Security Cookies</strong> <br /> We
            use Security Cookies for security purposes.
          </li>
          <li className="mt-2 text-lg">
            <strong className="font-medium">Advertising Cookies</strong> <br />{" "}
            Advertising Cookies are used to serve you with advertisements that
            may be relevant to you and your interests.
          </li>
          <h1 className="mt-10 text-black font-semibold text-3xl">
            Sensitive Personal Data and Information We Do Not Collect
          </h1>
          <p className="mt-2">
            We do not ask or collect any Sensitive Personal Data or information
            such as passwords related to other services, financial information
            such as bank account or credit card or debit card or other payment
            instrument details, physical, physiological, and mental health
            conditions, sexual orientation, medical records, and history, or
            biometric information.
          </p>
          <h1 className="text-3xl mt-10 text-black font-semibold">
            Use of data
          </h1>
          <p className="mt-2">
            ASD Dev Video Player for All Format uses the collected data for
            various purposes:
          </p>
          <li className="mt-2">To provide and maintain our Service</li>
          <li className="mt-2">To notify you about changes to our Service</li>
          <li className="mt-2">
            To allow you to participate in interactive features of our Service
            when you choose to do so
          </li>
          <li className="mt-2">To provide customer support</li>
          <li className="mt-2">
            To gather analysis or valuable information so that we can improve
            our Service
          </li>
          <li className="mt-2">To monitor the usage of our Service</li>
          <li className="mt-2">
            To detect, prevent and address technical issues
          </li>
          <br />
          <h1 className="mt-6 text-black font-semibold text-3xl">
            Legal basis for processing personel data under the
          </h1>
          <h1 className="mt-2 text-black text-3xl font-semibold">
            GENERAL DATA PROTECTION REGULATION (GDPR)
          </h1>
          <p className="mt-2 ">
            If you are from the European Economic Area (EEA), ASD Dev Video
            Player for All Format legal basis for collecting and using the
            personal information described in this Privacy Policy depends on the
            Personal Data we collect and the specific context in which we
            collect it.
          </p>
          <p className="mt-2 ">
            ASD Dev Video Player for All Format may process your Personal Data
            because:
          </p>
          <li className="mt-2">We need to perform a contract with you</li>
          <li className="mt-2">You have given us permission to do so</li>
          <li className="mt-2">
            The processing is in our legitimate interests and it is not
            overridden by your rights
          </li>
          <li className="mt-2">For payment processing purposes</li>
          <li className="mt-2">To comply with the law</li>
          <h1 className="text-3xl mt-8 text-black font-semibold">
            Third party tools:
          </h1>
          <p className="mt-2">
            Our Platform makes advantage of third party tools from YouTube. The
            YouTube API has been incorporated into our software to provide you
            smooth access to the platform's services. It's important to mention
            that if you use any of our services, third party may be notified
            about which parts of our Platform you have operated.
            <br />
            <br />
            Platform is being improved with the support of third-party partners.
            Under GDPR legislation, the use of these services is a legitimate
            interest as defined by Art. 6(1)(f) DSGVO.
          </p>
          <h1 className="text-3xl mt-8 text-black font-semibold">
            DMCA Copyright policy:
          </h1>
          <p className="mt-2">
            All Content available on the Platform is licenced from third-party
            services such as YouTube. All copyrights are owned by their
            respective owners and are used with permission. The Platform only
            provides outbound links to these Third-Party Services and has no
            direct control over their content. In compliance with the Digital
            Millennium Copyright Act, the Company has adopted the following
            general stance toward copyright infringement.
            {/* <a 
target='_blank'
className='text-blue-500 underline'
href="https://developers.google.com/youtube/terms/developer-policies#a.-api-client-terms-of-use-and-privacy-policies">API Client Terms of 
Use and Privacy Policies</a>  */}
            {/* to provide you smooth access to the platform's services.
It's important to mention that if you use any of our services, third party may be 
notified about which parts of our Platform you have operated. */}
          </p>
          {/* <p>Platform is being improved with the support of third-party partners. Under GDPR 
legislation, the use of these services is a legitimate interest as defined by Art. 
6(1)(f) DSGVO.</p> */}
          <li>
            <strong>Youtube Content:</strong> third party content available
            through the use of Google’s API (such as text, images, videos,
            audio, or software) hosted by Youtube.
          </li>
          <h1 className="text-3xl mt-8 text-black font-semibold">
            Third party Service providers:
          </h1>
          <p className="mt-2">
            As we've integrated{" "}
            <a
              className="text-blue-500 underline"
              target="_blank"
              rel="noreferrer"
              href="https://developers.google.com/youtube/terms/developer-policies#d.-accessing-youtube-api-services"
            >
              YouTube API Services
            </a>{" "}
            into our app so being a third -party service provider. We may share
            your information with YouTube that execute services on our behalf,
            including seamless music videos access
            <br />
            For more information about{" "}
            <a
              target="_blank"
              rel="noreferrer"
              className="text-blue-500 underline"
              href="https://www.youtube.com/t/terms"
            >
              YouTube's Terms of Services
            </a>{" "}
            . By using all these services, you are agreeing to be bound by
            <a
              href="https://policies.google.com/privacy"
              className="text-blue-500 underline ml-2"
              rel="noreferrer"
              target="_blank"
            >
              Google's Privacy Policy.
            </a>
          </p>
          <p className="mt-2">
            It is worth noting that our app is not sponsored or affiliated with
            YouTube, Facebook, Instagram, and other social sites in any manner.
          </p>
          <h1 className="text-3xl mt-8 text-black font-semibold">
            In-app Advertisements
          </h1>
          <p className="mt-4">
            We reserve the right to place adverts on the platforms in order to
            maintain the free functionalities. These advertisements may be
            interspersed with other features, and some of those features may
            only be activated if you view the ads. If you choose to stop seeing
            certain adverts, you might lose access to those features or
            encounter other app restrictions.
          </p>
          <p className="mt-2">
            Your use of the App may be terminated if you use any method to get
            around any In-app advertisements.
          </p>
          <h1 className="text-3xl mt-8 text-black font-semibold">
            In- app Purchases
          </h1>
          <p className="mt-4">
            We offer you choices with in-app purchases to improve your user
            experience and let you explore and take advantage of more features
            across the platform.
          </p>
          <p className="mt-2">
            If you wish to make any purchase, you do so through either Google
            Play service or Apple iTunes service and also agreeing to their
            respective Terms and Conditions.
          </p>
          <a
            href="https://play.google.com/intl/en-us/about/play-terms/index.html"
            className="text-blue-600"
          >
            https://play.google.com/intl/en-us/about/play-terms/index.html
          </a>{" "}
          <br />
          <a
            href="https://www.apple.com/legal/internet-services/itunes/"
            className="text-blue-600"
          >
            https://www.apple.com/legal/internet-services/itunes/
          </a>{" "}
          <br />
          <p className="mt-2">
            Also, It is to be noted that we’re not a party to any In-app
            purchases.
          </p>
          <h1 className="text-3xl mt-8 text-black font-semibold">
            Liability Limitation
          </h1>
          <p className="mt-2">
            ASD Dev Video Player for All Format Applications shall in no event
            be liable for any loss or damage (incidental, or consequential) of
            your device or files including photos, videos, documents, and music
            caused by-
            <br /> <br />
            <p className="ml-4">
              <li className="list-disc">
                Access or inability to access the services offered in our apps.
              </li>
              <li className="list-disc"> Any third party's conduct.</li>
              <li className="list-disc">
                {" "}
                Unauthorized or inappropriate use of ASD Dev Video Player for
                All Format Services in mobile applications other than what it
                was intended for.
              </li>
            </p>
          </p>
          <h1 className="text-3xl mt-8 text-black font-semibold">
            Disclaimer of Warranty
          </h1>
          <p className="mt-4">
            Services provided by ASD Dev Video Player for All Format through
            mobile applications are provided “as is and “as available,” with no
            warranties (expressed or implied). It also includes but is not
            limited to warranties of quality, marketability, fitness of services
            for particular purposes, or non-infringement. <br /> <br />
            You use the ASD Dev Video Player for All Format Service at your own
            risk and only as permitted by law.
          </p>
          <h1 className="text-3xl mt-8 text-black font-semibold">
            Copyright and Trademarks
          </h1>
          <p className="mt-4">
            Our apps give users the ability to create their own content. ASD Dev
            Video Player for All Formats will never claim ownership of your
            original work. You alone remain the sole person responsible for
            every piece of content you create, and therefore you warrant it.{" "}
            <br /> <br />
            whereas the service and content of ASD Dev Video Player for All
            Format Content are protected by copyright, trademark, patent, trade
            secret, and other laws.
            <br /> <br />
            ASD Dev Video Player for All Format owns and retains all rights to
            its content, applications, and services.
            <br /> <br />
            ASD Dev Video Player for All Formats also reserves the right to use
            your feedback (without revealing any personal information about the
            person who provided it) as we see fit. Under no circumstances can
            the sender of any particular feedback claim ownership of it or ask
            for payment in return.
          </p>
          <h1 className="text-3xl mt-8 text-black font-semibold">
            Push Notifications
          </h1>
          <p className="mt-4">
            We provide you the choice to receive push notifications to inform
            you of built-in features of the Application or any additional use of
            the Platform or you can say enabling In-app notifications is totally
            an alternative. If you turn off notifications, you might not get
            information right away and might miss-out latest updates information
            and lose access to some Platform services.
          </p>
          <h1 className="text-3xl mt-8 text-black font-semibold">
            Termination
          </h1>
          <p className="mt-2">
            Your access to any particular feature or the entire platform may be
            terminated if any violation is discovered in any way. The platform
            may be terminated with or without prior warning, and if for any
            reason you wish to do so, you may do so by deleting the application
            from your device.
          </p>
          <h1 className="text-3xl mt-8 text-black font-semibold">
            Retention of data
          </h1>
          <p className="mt-2">
            ASD Dev Video Player for All Format will retain your Personal Data
            only for as long as is necessary for the purposes set out in this
            Privacy Policy. We will retain and use your Personal Data to the
            extent necessary to comply with our legal obligations (for example,
            if we are required to retain your data to comply with applicable
            laws), resolve disputes and enforce our legal agreements and
            policies.
          </p>
          <p className="mt-2">
            ASD Dev Video Player for All Format will also retain Usage Data for
            internal analysis purposes. Usage Data is generally retained for a
            shorter period of time, except when this data is used to strengthen
            the security or to improve the functionality of our Service, or we
            are legally obligated to retain this data for longer periods.
          </p>
          <h1 className="text-3xl mt-8 text-black font-semibold">
            Data Recovery Policy{" "}
          </h1>
          <p className="mt-2">
            Our apps do not support Recoverable action after uninstallation of
            the app. If user is using any of our app that is built with Hider or
            Recover Files functionality then it is crucial to remember that
            after uninstalling the app, they may lose all the trash or hidden
            data.{" "}
          </p>
          <p className="mt-2">
            After uninstalling the app, our developers will not be liable for
            any of the private files or data.{" "}
          </p>
          <p className="mt-2">
            To prevent your data, you may transfer the trash or hidden files
            from our app to your device because once you will uninstall our app,
            you won't be able to recover those files .
          </p>
          <h1 className="text-3xl mt-8 text-black font-semibold">
            {" "}
            Transfer of data
          </h1>
          <p className="mt-2">
            Your information, including Personal Data, may be transferred to —
            and maintained on — computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
          </p>
          <p className="mt-2">
            you are located outside France and choose to provide information to
            us, please note that we transfer the data, including Personal Data,
            to France and process it there.
          </p>
          <p className="mt-2">
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p className="mt-2">
            <strong>ASD Dev Video Player for All Format</strong> will take all
            the steps reasonably necessary to ensure that your data is treated
            securely and in accordance with this Privacy Policy and no transfer
            of your Personal Data will take place to an organisation or a
            country unless there are adequate controls in place including the
            security of your data and other personal information.
          </p>
          <h1 className="text-3xl mt-8 text-black font-semibold">
            Disclosure of data
          </h1>
          <p className="mt-2 font-semibold">Business Transaction</p>
          <p className="mt-2">
            If ASD Dev Video Player for All Format is involved in a merger,
            acquisition or asset sale, your Personal Data may be transferred. We
            will provide notice before your Personal Data is transferred and
            becomes subject to a different Privacy Policy.
          </p>
          <p className="mt-2 font-semibold">Disclosure for Law Enforcement</p>
          <p className="mt-2">
            Under certain circumstances, ASD Dev Video Player for All Format may
            be required to disclose your Personal Data if required to do so by
            law or in response to valid requests by public authorities (e.g. a
            court or a government agency).
          </p>
          <p className="mt-8 font-semibold ">Legal Requirements</p>
          <p className="mt-2">
            ASD Dev Video Player for All Format may disclose your Personal Data
            in the good faith belief that such action is necessary to:
          </p>
          <li className="mt-2">To comply with a legal obligation </li>
          <li className="mt-2">
            To protect and defend the rights or property of ASD Dev Video Player
            for All Format
          </li>
          <li className="mt-2">
            To prevent or investigate possible wrongdoing Servicein connection
            with the{" "}
          </li>
          <li className="mt-2">
            To protect the personal safety of users of the Service or the public
          </li>
          <li className="mt-2"> To protect against legal liability</li>
          <br />
          <h1 className="text-black mt-2 text-3xl font-semibold">
            Security of data
          </h1>
          <p className="mt-2 ">
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>
          <br />
          <h1 className="text-black mt-4 text-3xl font-semibold">
            OUR POLICY ON “DO NOT TRACK” SIGNALS UNDER THE CALIFORNIA ONLINE
            PROTECTION ACT (CALOPPA)
          </h1>
          <p className="mt-2">
            We do not support Do Not Track (“DNT”). Do Not Track is a preference
            you can set in your web browser to inform websites that you do not
            want to be tracked.
          </p>
          <p className="mt-2">
            You can enable or disable Do Not Track by visiting the Preferences
            or Settings page of your web browser.
          </p>
          <br />
          <h1 className="mt-4 text-black text-3xl font-semibold">
            Your data protection rights under the GENERAL DATA PROTECTION
            REGULATION (GDPR)
          </h1>
          <p className="mt-2">
            If you are a resident of the European Economic Area (EEA), you have
            certain data protection rights. ASD Dev Video Player for All Format
            aims to take reasonable steps to allow you to correct, amend, delete
            or limit the use of your Personal Data.
          </p>
          <p className="mt-2">
            If you wish to be informed about what Personal Data we hold about
            you and if you want it to be removed from our systems, please
            contact us.
          </p>
          <p className="mt-2">
            In certain circumstances, you have the following data protection
            rights:
          </p>
          <li className="mt-2">
            The right to access, update or delete the information we have on
            you. Whenever made possible, you can access, update or request
            deletion of your Personal Data directly within your account settings
            section. If you are unable to perform these actions yourself, please
            contact us to assist you.
          </li>
          <li className="mt-2">
            The right of rectification. You have the right to have your
            information rectified if that information is inaccurate or
            incomplete.
          </li>
          <li className="mt-2">
            The right to object. You have the right to object to our processing
            of your Personal Data.
          </li>
          <li className="mt-2">
            The right of restriction. You have the right to request that we
            restrict the processing of your personal information.
          </li>
          <li className="mt-2">
            The right to data portability. You have the right to be provided
            with a copy of the information we have on you in a structured,
            machine-readable and commonly used format.
          </li>
          <li className="mt-2">
            The right to withdraw consent. You also have the right to withdraw
            your consent at any time where ASD Dev Video Player for All Format
            relied on your consent to process your personal information.
          </li>
          <p className="mt-2">
            Please note that we may ask you to verify your identity before
            responding to such requests.
          </p>
          <p className="mt-2">
            You have the right to complain to a Data Protection Authority about
            our collection and use of your Personal Data. For more information,
            please contact your local data protection authority in the{" "}
            <strong>European Economic Area (EEA)</strong>.
          </p>
          <br />
          <h1 className="text-black text-3xl mt-4 font-semibold">
            Service Providers
          </h1>
          <p className="mt-2">
            We may employ third party companies and individuals to facilitate
            our Service (“Service Providers”), provide the Service on our
            behalf, perform Service-related services or assist us in analyzing
            how our Service is used. These third parties have access to your
            Personal Data only to perform these tasks on our behalf and are
            obligated not to disclose or use it for any other purpose.
          </p>
          <br />
          <h1 className="text-black text-3xl mt-4 mb-3 font-semibold">
            Disclaimer:
          </h1>
          <p className="mt-2">
            The ASD Video Downloader app is not associated with any social media
            platform. Any unauthorized acts and/or violations of intellectual
            property rights are solely the user's responsibility. Before
            downloading and using the content, we suggest that you seek the
            permission of the owner.
          </p>
          <h1 className="text-black text-3xl mt-7 mb-3 font-semibold">
            Analytics
          </h1>
          <p>
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>
          <li>
            <strong>Facebook Analytics</strong>
            <p className="ml-2 mt-2">
              Facebook Analytics is provided by Facebook Inc.
            </p>
            <p className="ml-2 mt-2">
              We use Facebook Analytics for in-game data analytics to improve
              our games.
            </p>
            <p className="ml-2 mt-2">
              For more information on the privacy practices of Facebook, please
              visit Facebook’s Data Policy:{" "}
              <a
                href="https://www.facebook.com/privacy/explanation"
                className="text-blue-400"
              >
                https://www.facebook.com/privacy/explanation
              </a>{" "}
            </p>
          </li>
          <li>
            <strong>Firebase Analytics</strong>
            <p className="ml-2 mt-2">
              We use the developer platform called “Google Firebase” as well as
              the associated functions and services of Google Inc, 1600
              Amphitheatre Parkway, Mountain View, CA 94043, USA, (“Google”).
              Google Firebase is a platform for developing apps for mobile
              devices and websites. Google Firebase offers a variety of
              features, which are can be found on the following summary page:{" "}
              <a
                className="text-blue-400"
                href="https://firebase.google.com/products/"
              >
                https://firebase.google.com/products/
              </a>
            </p>
            <p className="ml-2 mt-2">
              The functions include the storage of apps, including users’
              personal data, such as content they have created or information
              regarding their interaction with the apps. Google Firebase also
              offers interfaces that allow interaction between the users of the
              app and other services.
            </p>
            <p className="ml-2 mt-2">
              The analysis of user interactions is carried out using the
              analysis service of Firebase Analytics. This service helps us to
              record our users’ interactions. Events such as the first time an
              app is opened, the uninstalling of an app, updates, crashes or the
              frequency of use of the app are recorded. Certain user interests
              are also recorded and evaluated.{" "}
            </p>
            <p className="ml-2 mt-2">
              The legal basis for use is Art. 6 paragraph 1 sentence 1 letter of
              GDPR. If users wish to object to interest-based advertising
              through Google marketing services, they can use the settings and
              opt-out options provided by Google:{" "}
              <a
                href="http://www.google.com/ads/preferences"
                className="text-blue-400"
              >
                http://www.google.com/ads/preferences{" "}
              </a>
              .
            </p>
            <p className="ml-2 mt-2">
              The information processed by Google Firebase may be used with
              other Google services, such as Google Analytics and Google
              marketing services. In this case, only pseudonymous information,
              such as the Android Advertising ID or the Advertising Identifier
              for iOS, will be processed to identify users’ mobile devices.
              Additional information on the use of data for marketing purposes
              by Google can be found on the summary page:{" "}
              <a
                href="https://www.google.com/policies/technologies/ads"
                className="text-blue-400"
              >
                https://www.google.com/policies/technologies/ads
              </a>
              , Google’s privacy policy is available at{" "}
              <a
                href="https://www.google.com/policies/privacy"
                className="text-blue-400"
              >
                https://www.google.com/policies/privacy
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>GameAnalytics</strong>
            <p className="mt-2 ml-2">
              GameAnalytics is provided by GameAnalytics.
            </p>
            <p className="mt-2 ml-2">
              We use GameAnalytics for in-game data analytics to improve our
              games.
            </p>
            <p className="mt-2 ml-2">
              For more information about GameAnalytics, please visit their
              Privacy Policy:{" "}
              <a
                href="https://gameanalytics.com/privacy"
                className="text-blue-400"
              >
                https://gameanalytics.com/privacy
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>Tenjin.io</strong>
            <p className="mt-2 ml-2">Tenjin is provided by Tenjin, Inc.</p>
            <p className="mt-2 ml-2">
              We use Tenjin for performance attribution.
            </p>
            <p className="mt-2 ml-2">
              For more information on what type of information Tenjin collects,
              please visit:{" "}
              <a
                href="https://www.tenjin.io/privacy/"
                className="text-blue-400"
              >
                https://www.tenjin.io/privacy/
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>Adjust</strong>
            <p className="mt-2 ml-2">Adjust is provided by Adjust GmbH.</p>
            <p className="mt-2 ml-2">
              We use Adjust for performance attribution.
            </p>
            <p className="mt-2 ml-2">
              For more information on what type of information Adjust collects,
              please visit:{" "}
              <a
                href=" https://www.adjust.com/terms/privacy-policy/"
                className="text-blue-400"
              >
                {" "}
                https://www.adjust.com/terms/privacy-policy/
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>AppsFlyer</strong>
            <p className="mt-2 ml-2">AppsFlyer is provided by AppFlyer</p>
            <p className="mt-2 ml-2">
              We use AppFlyer for performance attribution.
            </p>
            <p className="mt-2 ml-2">
              For more information on what type of information AppsFlyer
              collects, please visit:{" "}
              <a
                href=" https://www.appsflyer.com/privacy-policy/"
                className="text-blue-400"
              >
                {" "}
                https://www.appsflyer.com/privacy-policy/
              </a>
            </p>
          </li>
          <h1 className="text-black mt-8 text-4xl font-semibold">
            Advertising
          </h1>
          <p>
            We may use third-party Service Providers to show advertisements to
            you to help support and maintain our Service.
          </p>
          <li className="mt-2">
            <strong>Adincube</strong>
            <p className="mt-2 ml-2">
              Adincube is provided by Purple Brain SAS.
            </p>
            <p className="mt-2 ml-2">
              Please visit{" "}
              <a
                href="https://storage.googleapis.com/adincube-resources/doc/Privacy_policy.pdf"
                className="text-blue-400"
              >
                https://storage.googleapis.com/adincube-resources/doc/Privacy_policy.pdf
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>IronSource</strong>
            <p className="mt-2 ml-2">
              IronSource is provided by IronSource Mobile Ltd.
            </p>
            <p className="mt-2 ml-2">
              You can opt-out from IronSource network by following the
              instructions as described by Ironsource on their Privacy Policy
              page:{" "}
              <a href="https://developers.ironsrc.com/ironsource-mobile/air/ironsource-mobile-privacy-policy/">
                https://developers.ironsrc.com/ironsource-mobile/air/ironsource-mobile-privacy-policy/
              </a>
            </p>
            <p className="mt-2 ml-2">
              For more information about IronSource, please visit their Privacy
              Policy:{" "}
              <a
                href=" https://developers.ironsrc.com/ironsource-mobile/air/ironsource-mobile-privacy-policy/"
                className="text-blue-400"
              >
                {" "}
                https://developers.ironsrc.com/ironsource-mobile/air/ironsource-mobile-privacy-policy/
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>MoPub</strong>
            <p className="mt-2 ml-2">MoPub is provided by MobPub Inc..</p>
            <p className="mt-2 ml-2">
              You can opt-out from MobPub service by following the instructions
              on MoPub Opt-out page:{" "}
              <a className="text-blue-400" href="http://www.mopub.com/optout/">
                http://www.mopub.com/optout/
              </a>
            </p>
            <p className="mt-2 ml-2">
              For more information on what information MobPub collects and how
              it is used, please read MoPub Privacy Policy:{" "}
              <a
                href="http://www.mopub.com/legal/privacy/"
                className="text-blue-400"
              >
                http://www.mopub.com/legal/privacy/
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>Facebook Audience Network</strong>
            <p className="mt-2 ml-2">
              Facebook Audience Network is provided by Facebook Inc.
            </p>
            <p className="mt-2 ml-2">
              For more information about Facebook Audience Network, please visit
              their Privacy Policy:
            </p>
          </li>
          <li className="mt-2">
            <strong>AdMob</strong>
            <p className="mt-2 ml-2">AdMob is provided by Google Inc.</p>
            <p className="mt-2 ml-2">
              You can opt-out from the AdMob by Google service by following the
              instructions described by Google:{" "}
              <a
                className="text-blue-400"
                href="https://support.google.com/ads/answer/2662922?hl=en"
              >
                https://support.google.com/ads/answer/2662922?hl=en
              </a>
            </p>
            <p className="mt-2 ml-2">
              For more information on how Google uses the collected information,
              please visit the “How Google uses data when you use our partners’
              sites or app” page:{" "}
              <a
                href="http://www.google.com/policies/privacy/partners/"
                className="text-blue-400"
              >
                http://www.google.com/policies/privacy/partners/
              </a>{" "}
              or visit the Privacy Policy of Google:{" "}
              <a href="http://www.google.com/policies/privacy/">
                http://www.google.com/policies/privacy/
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>AppLovin</strong>
            <p className="mt-2 ml-2">
              AppLovin is provided by AppLovin Corporation.
            </p>
            <p className="mt-2 ml-2">
              If you’d like to opt-out from AppLovin service, please visit the
              AppLovin Opt-out page:{" "}
              <a
                className="text-blue-400"
                href="https://www.applovin.com/optout"
              >
                https://www.applovin.com/optout
              </a>
            </p>
            <p className="mt-2 ml-2">
              If you’d like to learn more about AppLovin, please visit the
              Privacy Policy page of AppLovin:
            </p>
          </li>
          <li className="mt-2">
            <strong>HyprMx</strong>
            <p className="mt-2 ml-2">
              HyprMx is provided by HyprMX Mobile LLC.
            </p>
            <p className="mt-2 ml-2">
              For more information about HyprMX, please visit their Privacy
              Policy:{" "}
              <a className="text-blue-400" href="https://www.hyprmx.com/pp">
                https://www.hyprmx.com/pp
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>PalmUp</strong>
            <p className="mt-2 ml-2">PalmUp is provided by Palmup SAS .</p>
            <p className="mt-2 ml-2">
              For more information about PalmUp, please visit their Privacy
              Policy:{" "}
              <a
                className="text-blue-400"
                href="https://docs.palmup.io/legal-section/privacy-policy"
              >
                https://docs.palmup.io/legal-section/privacy-policy
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>Pangle</strong>
            <p className="mt-2 ml-2">
              Pungle is provided by Bytedance Pte.Ltd..
            </p>
            <p className="mt-2 ml-2">
              For more information about Pungle, please visit their Privacy
              Policy:{" "}
              <a
                className="text-blue-400"
                href="https://ad.oceanengine.com/union/media/privacy"
              >
                https://ad.oceanengine.com/union/media/privacy
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>Fyber</strong>
            <p className="mt-2 ml-2">Fyber is provided by Fyber N.V</p>
            <p className="mt-2 ml-2">
              For more information about Fyber, please visit their Privacy
              Policy:{" "}
              <a
                className="text-blue-400"
                href="https://www.fyber.com/website-privacy-policy/"
              >
                https://www.fyber.com/website-privacy-policy/
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>Vungle</strong>
            <p className="mt-2 ml-2">Vungle is provided by Vungle Inc. </p>
            <p className="mt-2 ml-2">
              You can opt-out from Vungle service by following the instructions
              as described by Vungle on their Privacy Policy page:{" "}
              <a className="text-blue-400" href="http://vungle.com/privacy/">
                http://vungle.com/privacy/
              </a>
            </p>
            <p className="mt-2 ml-2 ">
              For more information about Vungle, please visit their Privacy
              Policy:{" "}
              <a className="text-blue-400" href="httlp://vungle.com/privacy/">
                httlp://vungle.com/privacy/
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>Mintegral</strong>
            <p className="mt-2 ml-2">
              Mintegral is provided by Mintegral International Ltd.
            </p>
            <p className="mt-2 ml-2">
              For more information about Mintegral, please visit their Privacy
              Policy:{" "}
              <a
                className="text-blue-400"
                href="https://www.mintegral.com/en/privacy/"
              >
                https://www.mintegral.com/en/privacy/
              </a>
            </p>
            <p className="mt-2 ml-2 ">
              Please find their official statement regarding SDK Allegations of
              August 2020:{" "}
              <a
                className="text-blue-400"
                href="https://www.mintegral.com/en/our-statement-regarding-recent-SDK-Allegations/"
              >
                https://www.mintegral.com/en/our-statement-regarding-recent-SDK-Allegations/
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>Unity Ads</strong>
            <p className="mt-2 ml-2">Unity Ads is provided by Unity.</p>
            <p className="mt-2 ml-2">
              For more information about Unity Ads, please visit their Privacy
              Policy:{" "}
              <a
                className="text-blue-400"
                href="https://unity3d.com/legal/privacy-policy"
              >
                https://unity3d.com/legal/privacy-policy
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>Ogury</strong>
            <p className="mt-2 ml-2">Ogury is provided by Ogury Limited.</p>
            <p className="mt-2 ml-2">
              For more information about Ogury, please visit their Privacy
              Policy:{" "}
              <a
                className="text-blue-400"
                href="https://www.ogury.com/privacy/"
              >
                https://www.ogury.com/privacy/
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>Tapjoy</strong>
            <p className="mt-2 ml-2">Tapjoy is provided by Tapjoy Inc.</p>
            <p className="mt-2 ml-2">
              You can opt-out from Tapjoy service by following the instructions
              as described by Tapjoy on their Privacy Policy page:{" "}
              <a className="text-blue-400" href="https://www.tapjoy.com/legal/">
                https://www.tapjoy.com/legal/
              </a>
            </p>

            <p className="mt-2 ml-2">
              For more information about Tapjoy, please visit their Privacy
              Policy:{" "}
              <a
                className="text-blue-400"
                href="https://dev.tapjoy.com/faq/tapjoy-privacy-policy/"
              >
                https://dev.tapjoy.com/faq/tapjoy-privacy-policy/
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>Chartboost</strong>
            <p className="mt-2 ml-2">
              Chartboost is provided by Chartboost Inc.
            </p>
            <p className="mt-2 ml-2">
              For more information about Chartboost, please visit their Privacy
              Policy:{" "}
              <a
                className="text-blue-400"
                href="https://answers.chartboost.com/en-us/articles/200780269"
              >
                https://answers.chartboost.com/en-us/articles/200780269
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>AdColony</strong>
            <p className="mt-2 ml-2">AdColony is provided by Jirbo Inc. </p>
            <p className="mt-2 ml-2">
              You can opt-out from AdColony service by visiting the AdColony
              Opt-out page:{" "}
              <a
                className="text-blue-400"
                href="http://www.adcolony.com/privacy-policy/opt-out/"
              >
                http://www.adcolony.com/privacy-policy/opt-out/
              </a>
            </p>
            <p className="mt-2 ml-2">
              You can also opt-out from AdColony through the Digital Advertising
              Alliance in the USA{" "}
              <a
                className="text-blue-400"
                href="http://www.aboutads.info/choices/"
              >
                http://www.aboutads.info/choices/
              </a>{" "}
              or opt-out using your mobile device settings.
            </p>
            <p className="mt-2 ml-2">
              For more information about AdColony, please visit their Privacy
              Policy:{" "}
              <a
                className="text-blue-400"
                href="http://www.adcolony.com/privacy-policy/"
              >
                http://www.adcolony.com/privacy-policy/
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>InMobi</strong>
            <p className="mt-2 ml-2">InMobi is provided by InMobi Pte Ltd. </p>
            <p className="mt-2 ml-2">
              You can opt-out from InMobi service by following the instructions
              as described on the InMobi Opt-out page:{" "}
              <a
                className="text-blue-400"
                href="http://www.inmobi.com/page/opt-out/"
              >
                http://www.inmobi.com/page/opt-out/
              </a>
            </p>
            <p className="mt-2 ml-2">
              For more information on the privacy practices and policies of
              InMobi, please visit the InMobi Privacy Policy:{" "}
              <a
                className="text-blue-400"
                href="http://www.inmobi.com/privacy-policy/"
              >
                http://www.inmobi.com/privacy-policy/
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>StartApp</strong>
            <p className="mt-2 ml-2">StartApp is provided by StartApp Inc </p>
            <p className="mt-2 ml-2">
              You can opt-out from interest-based ads served by StartApp by
              following the instructions on their Privacy Policy, section “8.
              Your Choices and Controls”:{" "}
              <a
                className="text-blue-400"
                href="https://www.startapp.com/policy/privacy-policy/"
              >
                https://www.startapp.com/policy/privacy-policy/
              </a>
            </p>
            <p className="mt-2 ml-2">
              For more information on the privacy practices and policies of
              InMobi, please visit the InMobi Privacy Policy:{" "}
              <a
                className="text-blue-400"
                href="http://www.inmobi.com/privacy-policy/"
              >
                http://www.inmobi.com/privacy-policy/
              </a>
            </p>
            <p className="mt-2 ml-2">
              For more information on what information StartApp collects and how
              it is used, please read their Privacy Policy:{" "}
              <a
                className="text-blue-400"
                href="https://www.startapp.com/policy/privacy-policy/"
              >
                https://www.startapp.com/policy/privacy-policy/
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>Smaato</strong>
            <p className="mt-2 ml-2">Smaato is provided by Smaato, Inc. </p>
            <p className="mt-2 ml-2">
              For more information on what information Smaato collects and how
              it is used, please read their Privacy Policy:{" "}
              <a
                className="text-blue-400"
                href="https://www.smaato.com/privacy/"
              >
                https://www.smaato.com/privacy/
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>Verizon</strong>
            <p className="mt-2 ml-2">
              Verizon is provided by Verizon Communication Inc.
            </p>
            <p className="mt-2 ml-2">
              For more information on what information Verizon collects and how
              it is used, please read their Privacy Policy:{" "}
              <a
                className="text-blue-400"
                href="https://www.verizon.com/about/privacy/"
              >
                https://www.verizon.com/about/privacy/
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>Google AdSense & DoubleClick Cookie</strong>
            <p className="mt-2 ml-2">
              Google, as a third party vendor, uses cookies to serve ads on our
              Service. Google’s use of the DoubleClick cookie enables it and its
              partners to serve ads to our users based on their visit to our
              Service or other websites on the Internet.
            </p>
            <p className="mt-2 ml-2">
              You may opt out of the use of the DoubleClick Cookie for
              interest-based advertising by visiting the Google Ads Settings web
              page:{" "}
              <a
                className="text-blue-400"
                href="http://www.google.com/ads/preferences/"
              >
                http://www.google.com/ads/preferences/
              </a>
            </p>
          </li>
          <h1 className="mt-8 text-black text-3xl font-semibold">
            Behavioral Remarketing
          </h1>
          <p className="mt-2 ">
            ASD Dev Video Player for All Format uses remarketing services to
            advertise on third party websites to you after you visited our
            Service. We and our third-party vendors use cookies to inform,
            optimise and serve ads based on your past visits to our Service.
          </p>
          <li className="mt-2">
            <strong>Google Ads</strong>
            <p className="mt-2 ml-2">
              Google AdWords remarketing service is provided by Google Inc.{" "}
            </p>
            <p className="mt-2 ml-2">
              You can opt-out of Google Analytics for Display Advertising and
              customise the Google Display Network ads by visiting the Google
              Ads Settings page:{" "}
              <a
                className="text-blue-400"
                href="http://www.google.com/settings/ads"
              >
                http://www.google.com/settings/ads
              </a>
            </p>
            <p className="mt-2 ml-2">
              Google also recommends installing the Google Analytics Opt-out
              Browser Add-on{" "}
              <a
                className="text-blue-400"
                href="https://tools.google.com/dlpage/gaoptout "
              >
                – https://tools.google.com/dlpage/gaoptout –
              </a>{" "}
              for your web browser. Google Analytics Opt-out Browser Add-on
              provides visitors with the ability to prevent their data from
              being collected and used by Google Analytics.
            </p>
            <p className="mt-2 ml-2">
              For more information on the privacy practices of Google, please
              visit the Google Privacy & Terms web page:{" "}
              <a
                className="text-blue-400"
                href="https://policies.google.com/privacy?hl=en"
              >
                https://policies.google.com/privacy?hl=en
              </a>
            </p>
          </li>
          <li className="mt-2">
            <strong>Facebook Ads</strong>
            <p className="mt-2 ml-2">
              Facebook remarketing service is provided by Facebook Inc.{" "}
            </p>
            <p className="mt-2 ml-2">
              You can learn more about interest-based advertising from Facebook
              by visiting this page:{" "}
              <a
                className="text-blue-400"
                href="https://www.facebook.com/help/164968693837950"
              >
                https://www.facebook.com/help/164968693837950
              </a>
            </p>
            <p className="mt-2 ml-2">
              To opt-out from Facebook’s interest-based ads, follow these
              instructions from Facebook:{" "}
              <a
                className="text-blue-400"
                href=" https://www.facebook.com/help/568137493302217"
              >
                {" "}
                https://www.facebook.com/help/568137493302217
              </a>
            </p>
            <p className="mt-2 ml-2">
              Facebook adheres to the Self-Regulatory Principles for Online
              Behavioural Advertising established by the Digital Advertising
              Alliance. You can also opt-out from Facebook and other
              participating companies through the Digital Advertising Alliance
              in the USA{" "}
              <a
                href="http://www.aboutads.info/choices/"
                className="text-blue-400"
              >
                http://www.aboutads.info/choices/
              </a>{" "}
              , the Digital Advertising Alliance of Canada in Canada{" "}
              <a
                className="text-blue-400"
                href="http://www.aboutads.info/choices/"
              >
                http://www.aboutads.info/choices/
              </a>{" "}
              or the European Interactive Digital Advertising Alliance in Europe{" "}
              <a
                className="text-blue-400"
                href="http://www.youronlinechoices.eu/"
              >
                http://www.youronlinechoices.eu/
              </a>{" "}
              , or opt-out using your mobile device settings.
            </p>
            <p>
              For more information on the privacy practices of Facebook, please
              visit Facebook’s Data Policy:{" "}
              <a
                className="text-blue-400"
                href="https://www.facebook.com/privacy/explanation"
              >
                https://www.facebook.com/privacy/explanation
              </a>
            </p>
          </li>
          <h1 className="mt-8 text-black text-3xl font-semibold">Payments</h1>
          <p className="mt-2 ">
            We may provide paid products and/or services within the Service. In
            that case, we use third-party services for payment processing (e.g.
            payment processors).
          </p>
          <p className="mt-2">
            We will not store or collect your payment card details. That
            information is provided directly to our third-party payment
            processors whose use of your personal information is governed by
            their Privacy Policy. These payment processors adhere to the
            standards set by PCI-DSS as managed by the PCI Security Standards
            Council, which is a joint effort of brands like Visa, MasterCard,
            American Express and Discover. PCI-DSS requirements help ensure the
            secure handling of payment information.
          </p>
          <p className="mt-2">The payment processors we work with are:</p>
          <li className="mt-2 text-lg">
            Apple Store In-App Payments
            <p className="mt-2 text-lg">
              Their Privacy Policy can be viewed at{" "}
              <a
                className="text-blue-400"
                href="https://www.apple.com/legal/privacy/en-ww/"
              >
                https://www.apple.com/legal/privacy/en-ww/
              </a>
            </p>
          </li>
          <li className="mt-2 text-lg">
            Google Play In-App Payments
            <p className="mt-2 text-lg">
              Their Privacy Policy can be viewed at{" "}
              <a
                className="text-blue-400"
                href="https://www.google.com/policies/privacy/"
              >
                https://www.google.com/policies/privacy/
              </a>
            </p>
          </li>
          <h1 className="mt-8 text-black text-3xl font-semibold">
            Links to other sites
          </h1>
          <p className="mt-2">
            Our Service may contain links to other sites that are not operated
            by us. If you click a third party link, you will be directed to that
            third party’s site. We strongly advise you to review the Privacy
            Policy of every site you visit. We have no control over and assume
            no responsibility for the content, privacy policies or practices of
            any third party sites or services.
          </p>
          <h1 className="text-black text-3xl mt-8 font-semibold">
            Children’s Privacy
          </h1>
          <p className="mt-2">
            Our Service does not address anyone under the age of 18
            (“Children”). We do not knowingly collect personally identifiable
            information from anyone under the age of 18. If you are a parent or
            guardian and you are aware that your Child has provided us with
            Personal Data, please contact us. If we become aware that we have
            collected Personal Data from children without verification of
            parental consent, we take steps to remove that information from our
            servers.
          </p>
          <h1 className="text-black text-3xl mt-8 font-semibold">
            Changes to this privacy policy
          </h1>
          <p className="mt-2">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update the
            “effective date” at the top of this Privacy Policy.
          </p>
          <p className="mt-2">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h1 className="mt-8 text-black text-3xl font-semibold">Contact us</h1>
          <p className="mt-2 mb-20">
            If you have any questions about this Privacy Policy, please contact
            us at info@rareprob.com.
          </p>
        </div>
        <div className=" pt-10">
          <NewFooter color="bg-[#6a1f9b]/90" />
        </div>
      </main>
    </>
  );
};

export default PrivacyPolicyScreen;
