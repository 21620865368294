import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
// import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { scroll } from "./ScrollToTop";
import { url } from "inspector";
// import headerBack from "../Image/headerBackground.png";
// import tree from "../Image/trees.png";
// import gift from "../Image/gift.png";
// import gifts from "../Image/gifts.png";
// import snowman from "../Image/snowman.png";

const Fade = require("react-reveal/Fade");

type HeaderProp = {
  color: string;
  page: string;
};

const Header: React.FC<HeaderProp> = ({
  color = "bg-black/25",
  page = "home",
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isOpenApp, setIsOpenApp] = React.useState<boolean>(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <nav
      className={`flex items-start  justify-between  backdrop-blur-xl bg-cover   ${color} h-[65px] pt-2 pb-0   lg:pt-0 xl:pt-3 ${
        page === "games" ? "fixed lg:static" : "fixed"
      } w-[100%] z-50 top-0 header-bg `}
      // style={{
      //   backgroundImage: `url(${headerBack})`,
      // }}
    >
      {/* <img src={tree} alt="" className="tree-nav" />
      <img src={snowman} alt="" className="snowman" />
      <img src={gift} alt="" className="gift" />
      <img src={gifts} alt="" className="gifts" /> */}
      <div className="flex items-start flex-shrink-0 text-white mr-6 ">
        <Link
          className="text-white  no-underline hover:text-white hover:no-underline"
          to="/"
        >
          <img
            src="/images/homepage/rare-logo.webp"
            className="w-40  h-8 ml-[9vw]"
            alt="Rareprob  "
            onClick={scroll}
          />
        </Link>
      </div>
      {/* <div className="flex items-center justify-center space-x-10">
        <img src="/images/icons/videoplayer.png" alt="Rocks Video Player | Rareprob" className="w-6 h-6 rounded-lg"/>
       <img src="/images/icons/file mana.webp.png" alt="File Manager | Rareprob" className="w-6 h-6 rounded-lg"/>
      </div> */}

      <div className="block lg:hidden">
        <button
          id="nav-toggle"
          onClick={toggleDrawer}
          // onClick={(e:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
          //     var navContent:HTMLElement | null=document.getElementById("nav-content")
          //     if(navContent==null){
          //         return ;
          //     }
          //     navContent.classList.toggle("hidden")
          // }}
          className="flex items-center px-3 py-2  rounded text-white  hover:text-white "
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
        <Drawer
          overlayOpacity={0.7}
          open={isOpen}
          onClose={toggleDrawer}
          direction="right"
          className="!backdrop-blur-2xl  !w-40 h-screen 
          mt-14 !bg-gray-900/50 z-20"
        >
          <div
            className={` flex flex-col text-xl  items-center justify-start pt-16 h-full z-20 relative `}
          >
            <ul className="list-reset lg:flex justify-end flex-1 space-y-2 items-center z-20">
              <li className="mr-3">
                <Link
                  className={`inline-block py-2 px-4 ${
                    page === "home"
                      ? "text-white text-[16px]"
                      : "text-gray-400 text-[14px]"
                  } hover:text-gray-200  no-underline`}
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="mr-3">
                <Link
                  className={`inline-block ${
                    page === "game"
                      ? "text-white text-[16px]"
                      : "text-gray-400 text-[14px]"
                  } no-underline hover:text-gray-200 hover:text-underline py-2 px-4`}
                  to="/games"
                >
                  Games
                </Link>
              </li>
              <li className="mr-3">
                <Link
                  className={`inline-block ${
                    page === "about"
                      ? "text-white text-[16px]"
                      : "text-gray-400 text-[14px]"
                  } no-underline hover:text-gray-200 hover:text-underline py-2 px-4`}
                  to="/about-us"
                >
                  About us
                </Link>
              </li>
              <li className="mr-3">
                <Link
                  className={`inline-block ${
                    page === "apps"
                      ? "text-white text-[16px]"
                      : "text-gray-400 text-[14px]"
                  }  no-underline hover:text-gray-200 hover:text-underline py-2 px-4`}
                  to="/apps"
                >
                  Apps
                </Link>
              </li>
              <li className="mr-3">
                <Link
                  className={`inline-block ${
                    page === "blogs"
                      ? "text-white text-[16px]"
                      : "text-gray-400 text-[14px]"
                  }  no-underline hover:text-gray-200 hover:text-underline py-2 px-4`}
                  to="/blogs"
                >
                  Blogs
                </Link>
              </li>
              <li className="mr-3">
                <Link
                  className={`inline-block ${
                    page === "faq"
                      ? "text-white text-[16px]"
                      : "text-gray-400 text-[14px]"
                  }  no-underline hover:text-gray-200 hover:text-underline py-2 px-4`}
                  to="/faq"
                >
                  FAQs
                </Link>
              </li>
              <li className="mr-3">
                <Link
                  className={`inline-block ${
                    page === "contact"
                      ? "text-white text-[16px]"
                      : "text-gray-400 text-[14px]"
                  }  no-underline hover:text-gray-200 hover:text-underline py-2 px-4`}
                  to="/contact-us"
                >
                  Contact
                </Link>
              </li>
              <li className="mr-3">
                <Link
                  className={`inline-block ${
                    page === "career"
                      ? "text-white text-[16px]"
                      : "text-gray-400 text-[14px]"
                  }  no-underline hover:text-gray-200 hover:text-underline py-2 px-4`}
                  to="/career"
                >
                  Career
                </Link>
              </li>
              <li className="mr-3">
                <Link
                  className={`inline-block ${
                    page === "privacy"
                      ? "text-white text-[16px]"
                      : "text-gray-400 text-[14px]"
                  }  no-underline hover:text-gray-200 hover:text-underline py-2 px-4`}
                  to="/privacy-policy"
                >
                  Privacy
                </Link>
              </li>
            </ul>
          </div>
        </Drawer>
      </div>

      <div
        className="w-full  flex-grow lg:flex lg:items-center lg:w-auto hidden  pt-6 lg:pt-0 mr-[0vw] xl:mr-[9vw]"
        id="nav-content"
      >
        <ul className="list-reset lg:flex justify-end flex-1 items-center">
          <li
            className="mr-3"
            onPointerEnter={() => setIsOpenApp(false)}
            onClick={scroll}
          >
            <Link
              className={`inline-block py-2 px-4 flex-grow ${
                page === "home" ? "text-white font-bold" : "text-gray-400"
              } hover:text-gray-200  no-underline`}
              to="/"
            >
              Home
            </Link>
          </li>
          <li
            className="mr-3"
            onPointerEnter={() => setIsOpenApp(false)}
            onClick={scroll}
          >
            <Link
              className={`inline-block py-2 px-4 ${
                page === "game" ? "text-white font-bold" : "text-gray-400"
              } no-underline hover:text-gray-200`}
              to="/games"
            >
              Games
            </Link>
          </li>
          <li
            className="mr-3"
            onPointerEnter={() => setIsOpenApp(false)}
            onClick={scroll}
          >
            <Link
              className={`inline-block ${
                page === "about" ? "text-white font-bold" : "text-gray-400"
              } no-underline hover:text-gray-200 hover:text-underline py-2 px-4`}
              to="/about-us"
            >
              About us
            </Link>
          </li>
          <li
            className="mr-3 "
            onPointerEnter={() => setIsOpenApp(true)}
            onClick={scroll}
          >
            <Link
              className={`inline-block ${
                page === "apps" ? "text-white font-bold" : "text-gray-400"
              }  no-underline hover:text-gray-200 hover:text-underline py-2 px-4`}
              to="/apps"
            >
              Apps
            </Link>

            {isOpenApp && (
              <Fade>
                <div
                  className={`bg-transparent !-z-40 h-0 rounded-b-2xl flex items-start justify-end !w-[74vw] left-0 absolute mt-3`}
                  onPointerLeave={() => setIsOpenApp(false)}
                >
                  <div
                    style={{ borderTop: " 2px solid white" }}
                    className={` bg-black/80 backdrop-blur-lg p-6 pt-2 text-md  text-gray-300 mt-0 space-y-1 text-left  rounded-2xl h-54 w-52`}
                  >
                    <Link
                      to={"/apps/rocks-video-player-app"}
                      className=" hover:text-lg  hover:font-medium  font-light transition-all block"
                    >
                      Video Player
                    </Link>
                    <Link
                      to="/apps/file-manager-app"
                      className=" hover:text-lg  hover:font-medium  font-light transition-all block"
                    >
                      File Manager
                    </Link>
                    <Link
                      to="/apps/music-player-mp4-mp3-player-app"
                      className=" hover:text-lg  hover:font-medium  font-light transition-all block"
                    >
                      Music Player
                    </Link>
                    <Link
                      to="/apps/video-downloader-files-hider-app"
                      className=" hover:text-lg  hover:font-medium  font-light transition-all block"
                    >
                      Video Downloader
                    </Link>
                    <Link
                      to="/apps/video-to-mp3-converter-app"
                      className=" hover:text-lg  hover:font-medium  font-light transition-all block"
                    >
                      Video To Mp3
                    </Link>

                    <Link
                      to="/apps/radio-monkey-online-radio-fm-app"
                      className=" hover:text-lg  hover:font-medium  font-light transition-all block"
                    >
                      Radio Monkey
                    </Link>

                    <Link
                      to="/apps/gallery-photo-editor-and-collage-maker-app"
                      className=" hover:text-lg  hover:font-medium  font-light transition-all block"
                    >
                      Gallery
                    </Link>
                  </div>
                </div>
              </Fade>
            )}
          </li>
          <li
            className="mr-3"
            onPointerEnter={() => setIsOpenApp(false)}
            onClick={scroll}
          >
            <Link
              className={`inline-block ${
                page === "blogs" ? "text-white font-bold" : "text-gray-400"
              }  no-underline hover:text-gray-200 hover:text-underline py-2 px-4`}
              to="/blogs"
            >
              Blogs
            </Link>
          </li>
          <li
            className="mr-3"
            onPointerEnter={() => setIsOpenApp(false)}
            onClick={scroll}
          >
            <Link
              className={`inline-block ${
                page === "faq" ? "text-white font-bold" : "text-gray-400"
              }  no-underline hover:text-gray-200 hover:text-underline py-2 px-4`}
              to="/faq"
            >
              FAQs
            </Link>
          </li>
          <li
            className="mr-3"
            onPointerEnter={() => setIsOpenApp(false)}
            onClick={scroll}
          >
            <Link
              className={`inline-block ${
                page === "career" ? "text-white font-bold" : "text-gray-400"
              }  no-underline hover:text-gray-200 hover:text-underline py-2 px-4`}
              to="/career"
            >
              Career
            </Link>
          </li>
          <li
            className="mr-3"
            onPointerEnter={() => setIsOpenApp(false)}
            onClick={scroll}
          >
            <Link
              className={`inline-block ${
                page === "contact" ? "text-white font-bold" : "text-gray-400"
              }  no-underline hover:text-gray-200 hover:text-underline py-2 px-4`}
              to="/contact-us"
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>

      {/* <Drawer
        overlayOpacity={0.0}
        open={isOpenApp}
        onClose={()=>setIsOpenApp(prev=>!prev)}
        direction="right"
        className="!backdrop-blur-2xl  !w-[200px] rounded-l-3xl !mx-auto h-screen  mt-16
                 !bg-white !-z-10
                 "
      ></Drawer> */}
    </nav>
  );
};

export default Header;
