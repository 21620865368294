import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const OurTeam = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full mx-auto text-white ">
      <h1 className="text-xl md:text-3xl ml-10 mt-20"> OUR TEAM</h1>
      <h1 className="text-[30px]  md:text-[55px] mt-4 ml-10  mb-20">
        Meet the Team
      </h1>
      <div className="flex md:flex-row flex-col items-center  space-y-32 md:space-y-10 md:space-x-20  mt-20 lg:mt-10 justify-center">
        <div className="w-[300px] md:w-[500px] h-[590px] md:h-[400px]  backdrop-blur-[500px] bg-opacity-20 bg-white shadow-2xl  flex flex-col md:flex-row items-center justify-around md:justify-between rounded-3xl mt-10 space-x-5">
          <div className="relative">
            <div className="bg-gradient-to-t from-[#242777] to-[#66369C] w-44 h-44 absolute -left-10 -top-10 rounded-full"></div>
            <img
              src="/images/aboutScreen/preeti.webp"
              alt="Preeti Saini"
              className="rounded-3xl object-cover w-64 h-[250px] md:h-[400px] pt-10 object-top relative bg-gradient-to-b from-[#e6afa9] to-[#ffb4ac]"
            />
          </div>
          <div>
            <h1 className="text-2xl md:text-4xl font-bold mr-4">
              Preeti Saini
            </h1>
            <h1>Co-Founder</h1>
            <h1 className="w-44 text-xs">
              To build a strong family before building the strong business is
              our criteria to work in the field of app development. We are
              providing services with the highly versatile team members who are
              working passionately which aligns the development of productivity
              apps for the people.
            </h1>
          </div>
        </div>
        <div className="w-[300px] md:w-[520px] h-[590px] md:h-[400px] backdrop-blur-[500px] bg-opacity-20 bg-white shadow-2xl  flex flex-col md:flex-row items-center justify-around md:justify-between rounded-3xl mt-10 space-x-3">
          <div className="relative">
            <div className="bg-gradient-to-t from-[#242777] to-[#66369C] w-44 h-44 absolute -left-10 -top-10 rounded-full"></div>
            <img
              src="/images/aboutScreen/ashish (1).webp"
              alt="Ashish Saini"
              className="rounded-3xl object-top object-cover md:object-cover w-64 h-[250px] md:h-[400px] pt-2 md:pt-10 bg-gradient-to-b from-[#bebebe] to-[#eeeeee] relative"
            />
          </div>
          <div className="mr-20 pr-4">
            <h1 className="text-2xl md:text-4xl font-bold">Ashish Saini</h1>
            <h1>CEO</h1>
            <h1 className="w-44 text-xs ">
              Gratitude from Rareprob! Our Passionate team always work with all
              their heart to advance our company to more milestones in the field
              of app development. Being the CEO of Rareprob, I always perceive
              my company as a unit and my prominence is on exploring and
              expanding the new IT concepts to develop utility and prime
              applications.
            </h1>
          </div>
        </div>
      </div>

      <Slider {...settings} className="rareprobTeamSlider">
        <AppLinks
          name={"Ravinder Kumar"}
          image={"/images/aboutScreen/ca ravinder sir.webp"}
          profile="Chartered Accountant"
        />

        <AppLinks
          name={"Shipra Pokhriyal"}
          image={"/images/aboutScreen/shipra.webp"}
          profile="Digital Marketing Executive"
        />
        <AppLinks
          name={"Rachana Rawat"}
          image={"/images/aboutScreen/rachnaNew.webp"}
          profile="Product Analyst"
        />

        <AppLinks
          name={"Mansi"}
          image={"/images/aboutScreen/manshi_.webp"}
          profile="Software Engineer"
        />
        <AppLinks
          name={"Arun Saini"}
          image={"/images/aboutScreen/arun.webp"}
          profile="Software Engineer"
        />
        <AppLinks
          name={"Vivek Saini"}
          image={"/images/aboutScreen/Group 4.webp"}
          profile="Unity Developer"
        />
        <AppLinks
          name={"Sapna Pandey"}
          image={"/images/aboutScreen/hr_.webp"}
          profile="Human Resources"
        />
        <AppLinks
          name={"Swati Karanwal"}
          image={"/images/aboutScreen/swati.webp"}
          profile="Software Engineer ( QA )"
        />
        <AppLinks
          name={"Robin Sharma"}
          image={"/images/aboutScreen/robin.webp"}
          profile="Software Engineer"
        />
        <AppLinks
          name={"Sanidhya Singh"}
          image={"/images/aboutScreen/sanithiya_.webp"}
          profile="Software Engineer"
        />

        <AppLinks
          name={"Aman Kumar"}
          image={"/images/aboutScreen/Artboard 1.png"}
          profile="Software Engineer"
        />
        <AppLinks
          name={"Sahil Jain"}
          image={"/images/aboutScreen/SURAJ.webp"}
          profile="Unity Developer"
        />
        <AppLinks
          name={"Shantanu Pradhan"}
          image={"/images/aboutScreen/shantanuNew.webp"}
          profile="Unity Devloper"
        />
        <AppLinks
          name={"Muskan Dhiman"}
          image={"/images/aboutScreen/Artboard 2.webp"}
          profile="Graphic Designer"
        />
        <AppLinks
          name={"Geetesh Sharma"}
          image={"/images/aboutScreen/GeeteshNew.webp"}
          profile="Software Engineer ( QA )"
        />
      </Slider>
    </div>
  );
};

export default OurTeam;

interface UserProfileType {
  name: string;
  image: string;
  profile: string;
}

const AppLinks: React.FC<UserProfileType> = React.memo(function ChildComponent({
  name,
  image,
  profile,
}) {
  return (
    <div className="text-center text-white justify-center flex flex-col mt-10  py-10 px-1 mx-4 rounded-3xl">
      <img
        src={image}
        alt={name}
        className="object-contain w-32 h-32 lg:w-[200px] lg:h-[200px] items-center mx-auto relative top-8 lg:top-20"
      />
      <div className="pt-10 md:pt-20  md:pb-10 h-[170px] md:h-[200px]  bg-opacity-20 bg-white hover:shadow-[0_0px_60px_1px_rgba(0,0,0,0.2)]   rounded-3xl">
        <h1 className="text-lg md:text-xl font-medium mt-4">{name}</h1>
        <p className="text-xs pb-4">{profile}</p>
      </div>
    </div>
  );
});
